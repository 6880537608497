import React, { useState, useEffect, useMemo } from "react"

import { FormatCurrency } from "../../../utils/utils"
import { MONTH_YEAR_ROLLOVER, GetYearFilterOptions, GetDefaultSelectedYear, FilterRecords } from "../../../../utils/reports"


// @ts-ignore
import * as styles from "../TableStyles.module.css"

function AlaCarteReportTable():JSX.Element {
  const [records,setRecords] = useState<Array<AlACarteRegistrationRecord>>([]),
        [isLoading,setIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),
        [yearOptions,setYearOptions] = useState<Array<number>>([]),
        [filteredYear,setFilteredYear] = useState<number>()

  function loadRecords () {
    setIsLoading(true)
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports?t=alacarte`, {
      method: "GET"
    })
    .then((response)=>{
      response.text()
      .then((data:string)=>{
        let response = JSON.parse(data)
        let newRecords = response.data.map((ref:any)=>{
          return {
            ...ref.data,
            last_modified: new Date(ref.ts/1000)
          }
        }).filter((a:any) => { return a ? true : false})
          .filter((a:any) => { return a.payment_status && a.payment_status === "paid" ? true : false})

        setRecords(newRecords)
        setFilteredYear(GetDefaultSelectedYear())
        setYearOptions(GetYearFilterOptions())
        setIsLoading(false)
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to load records")
      })
    })
    .catch((err)=>{
      setIsLoading(false)
      setErrorMessage("Failed to load records")
    })
  }

  useEffect(()=>{
    loadRecords()
  }, [])

  function copyData() {
    let data = records.map((record) => {
      const alacarteData = record as AlACarteRegistrationRecord
      return [
        alacarteData.last_modified?.toLocaleString() || "",
        alacarteData.firstName,
        alacarteData.lastName,
        alacarteData.affiliation,
        alacarteData.telephone,
        alacarteData.email,
        alacarteData.attendee_name,
        alacarteData.additionalBanquetAdultMeals,
        alacarteData.additionalBanquetChildMeals,
        FormatCurrency(alacarteData.amount_charged || 0),
      ].join('\t')
    }).join('\n')
    navigator.clipboard.writeText(data)
  }

  function onUpdateFilter(e:React.ChangeEvent<HTMLSelectElement> ) {
    try {
      let newYear :number|undefined
      newYear = parseInt(e.target.value)
      if(newYear && newYear > 2020) {
        setFilteredYear(newYear)
        return
      }
    } catch(err) {
    }
    setFilteredYear(undefined)
  }

  const visibleResults:Array<FormRecord> = useMemo(()=>{
    return FilterRecords(records, filteredYear)
  },[yearOptions,filteredYear,records])


  return (
    <div>
      <div className={styles.infoBar}>
        <div>
          <button onClick={copyData}>Copy</button>
          <p style={{paddingLeft: 10}}>{`${visibleResults.length} Records`}</p>
        </div>
        {isLoading ? (<p>Loading...</p>) : null}
        <div>
          <span className={styles.field}>
            <label title={`Years rollover on the ${MONTH_YEAR_ROLLOVER+1} month `}>Year</label>
            <select value={filteredYear} onChange={onUpdateFilter}>
              <option value="">All</option>
              {yearOptions.map((yr)=>(
                <option key={yr} value={yr}>{`${MONTH_YEAR_ROLLOVER+1}/${yr-1} through ${MONTH_YEAR_ROLLOVER}/${yr}`}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
      {errorMessage ? (<p>{errorMessage}</p> ) : null}
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Affiliation</th>
              <th>Telephone</th>
              <th>Email</th>
              <th>Attendee Name</th>
              <th>Extra Adult Meals</th>
              <th>Extra Child Meals</th>
              <th>Amount Charged</th>
            </tr>
          </thead>
          <tbody>
            {visibleResults.map((record)=>{
              let d = record as AlACarteRegistrationRecord
              return (
                <tr key={d.client_reference_id}>
                  <td>{record.last_modified?.toLocaleString()}</td>
                  <td>{d.firstName}</td>
                  <td>{d.lastName}</td>
                  <td>{d.affiliation}</td>
                  <td>{d.telephone}</td>
                  <td>{d.email}</td>
                  <td>{d.attendee_name}</td>
                  <td>{d.additionalBanquetAdultMeals}</td>
                  <td>{d.additionalBanquetChildMeals}</td>
                  <td>{FormatCurrency(d.amount_charged || 0)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AlaCarteReportTable